import React, { useState, useEffect  } from "react";
import Chunk from "./Chunk";

const TwoColumnContent = props => {
  const minWidth = 1450;
  const initMobile =()=>{
    if(typeof document !== "undefined"){
      console.log(window.innerWidth)
      return (window.innerWidth <= minWidth)
    }
  }  
  const [isMobile, setMobile] = useState(initMobile);
    const updateMedia = () => {
      if (typeof document !== "undefined") {
        setMobile(window.innerWidth <= minWidth)
      }
    }

  useEffect(()=>{
    if (typeof document !== "undefined"){
      window.addEventListener("resize", updateMedia);
      return ()=> window.removeEventListener("resize", updateMedia);
    }
  });
  

  const columnOne = (props) =>{
    return (<Chunk
      chunk={props.columns.columnOne.chunk}
      langKey={props.langKey}
      reusable={props.reusable}
    />
    )
  }

  const columnTwo = (props) =>{
    return(
      <Chunk
        chunk={props.columns.columnTwo.chunk}
        langKey={props.langKey}
        reusable={props.reusable}
      />
    )
  }
  return (
    <>
      {(isMobile )  ? props.columns !== null &&
        // The mobile version needs to re-arrange the columns to image -> text -> image -> text
          <div className="content" style={{marginTop: "0rem", marginBottom:"0rem",}}>
          {
            props.columns.columnOne.chunk[0].type === "imageFull" ? 
            <>
              <div className="content" >
                {columnOne(props) }
              </div>
              <div className="content">
                {columnTwo(props) }
              </div>  
            </>
            :
            <>
              <div>
                {columnTwo(props) }
              </div>
              <div>
                 {columnOne(props) }
              </div>
            </>
          }
        </div> : 
        props.columns !== null &&
        <div className="columns" style={{marginTop: "0rem", marginBottom:"-0.35rem", padding:"0rem" }} >
          <div className="column" style={{margin: "0rem", padding:"0rem" }}>
            {props.columns.columnOne !== null &&
              <Chunk
                chunk={props.columns.columnOne.chunk}
                langKey={props.langKey}
                reusable={props.reusable}
              />
            }
          </div>

          <div className="column" style={{margin: "0rem", padding:"0rem"}}>
            {props.columns.columnTwo !== null &&
              <Chunk
                chunk={props.columns.columnTwo.chunk}
                langKey={props.langKey}
                reusable={props.reusable}
              />
            }
          </div>
        </div>
      }
      
    </>
  );
};

export default TwoColumnContent