import React from "react";
import remark from "remark";
import parse from "remark-parse";
import remarkToreact from 'remark-react';
import remarkAttr from 'remark-attr';

const Table = props => {

  return (

    <div
      style={{
        textAlign: !!props.data.textAlign ? props.data.textAlign : "center",
        marginTop: !!props.data.marginTop ? `${props.data.marginTop}rem` : "0rem",
        marginLeft: !!props.data.marginLeft ? `${props.data.marginLeft}rem` : "10rem",
        marginRight: !!props.data.marginRight ? `${props.data.marginRight}rem` : "0rem",
        marginBottom: !!props.data.marginBottom ? `${props.data.marginBottom}rem` : "0rem"
      }}>
      <p className='is-size-12 has-text-weight-bold'>
        {props.title}
      </p>
      <div className={`${props.data.isScrollable ? "table-container" : null}`} >
        <table className={`table ${props.data.isStriped ? "is-striped" : null } is-fullwidth`} >
          {props.data.header &&
            <thead>
              <tr>
                {props.data.header.map(header => (
                  <th style={{backgroundColor: `${header.color}`, color: `${header.textColor}`}}>
                    {header.cell !== null &&
                      <>
                        {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(header.cell).contents}
                      </>
                    }
                  </th>
                ))}
              </tr>
            </thead>
          }
          {props.data.footer &&
            <tfoot>
              <tr>
                {props.data.footer.map(footer => (
                  <th style={{backgroundColor: `${footer.color}`, color: `${footer.textColor}`}}>
                    {footer.cell !== null &&
                      <>
                        {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(footer.cell).contents}
                      </>
                    }
                  </th>
                ))}
              </tr>
            </tfoot>
          }
          <tbody>
            {props.data.rows.map(rows => (
                
                <tr>
                  {props.data.isList ? 
                    rows.row.map(row => (
                      <>
                        {row.cell !== null && 
                          <td colSpan={ row.colSpan} style={{borderStyle: `${props.data.borderStyle}`, backgroundColor: `${row.color}`, color: `${row.textColor}`, padding: "0rem"}}>
                            <ul>
                              <li>{remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(row.cell).contents}</li>
                            </ul>
                          </td>
                        }
                      </>
                    )) :
                    rows.row.map(row => (
                      <>
                        {row.cell !== null && 
                          <td colSpan={ row.colSpan} style={{borderStyle: `${props.data.borderStyle}`, backgroundColor: `${row.color}`, color: `${row.textColor}`}}>
                            {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(row.cell).contents}
                          </td>
                        }
                      </>
                    ))
                  }
                </tr>
            ))}
          </tbody>
        </table>
      {props.note &&
        <p>
          {props.note}
        </p>
      }
      </div>
    </div>
  );
};

export default Table;