import React from "react";
import remark from "remark";
import parse from "remark-parse";
import remarkToreact from 'remark-react';
import { Link } from "gatsby";

const Jumbotron = props => {

  var justifyContent = ''
  var align = ''
  switch(props.data.align) {
    case 'center':
      justifyContent = 'center'
      align = 'has-text-centered'
      break
    case 'left':
      justifyContent = 'flex-start'
      align = 'has-text-left'
      break
    case 'right':
      justifyContent = 'flex-end'
      align = 'has-text-right'
      break
  }

  return (
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!props.data.image.childImageSharp ? props.data.image.childImageSharp.fluid.src : props.data.image
        })`,
        backgroundPosition: 'top center',
        backgroundAttachment: 'fixed',
        height: `${props.data.height}px`,
        position: 'relative',
        justifyContent: `${justifyContent}`,
        maxWidth: `100%`
      }}
    >
      <div
        className="background-overlay"
        style={{
          backgroundColor: `${props.data.overlay.backgroundColor}`,
          opacity: `${props.data.overlay.opacity}`
        }}
      />
      <div
        className={`${align} has-text-white-ter`}
        style={{
          lineHeight: '1',
          flexDirection: 'column',
          zIndex: 1,
          padding: '0rem 3rem'
        }}
      >
        {props.data.titles != null ? (
          <>
            {props.data.titles.map(title => (
              <div
                className={`${title.sizeMobile}-mobile ${title.sizeTablet}-tablet ${title.sizeWidescreen}-widescreen`}
                style={{
                  lineHeight: '1',
                  maxWidth: '100%',
                  paddingBottom: '1.5rem',
                  textAlign: `${title.align}`,
                }}
              >
                {remark().use(parse).use(remarkToreact).processSync(title.value).contents}
              </div>
            ))}
            {/* row of buttons */}
            {props.data.buttons ? (
              <div className="content"
                style={{
                  textAlign: "center",
                  paddingBottom: '1.5rem',
                }}>
                {props.data.buttons.map(button => (
                  <Link style={{margin: "0.5rem"}}
                      to={button.link}
                      className="button is-dark is-small"
                    >
                      {button.value}
                  </Link>
                ))}
            </div>) : null
            }
            
            
            

          </>
        ) : null}
      </div>
    </div>
  );
};

export default Jumbotron;